import React, { useEffect, useRef, useState } from "react"
import { graphql, Link } from "gatsby"
import ReactPlayer from "react-player"
import { Modal } from "react-responsive-modal"
import "react-responsive-modal/styles.css"

import Lightbox from "react-image-lightbox"
import { JSONLD, Product, Generic } from "react-structured-data"
import "react-image-lightbox/style.css"

import Layout from "../components/layout/layout"
import SEO from "../components/seo"
import InquiryForm from "../components/buy-equipment/inquiry-form"
import Social from "../components/social/social"
import Breadcrumb from "../components/layout/breadcrumb"

const ProductDetail = ({ data, location }) => {
  const tabs = useRef(null)
  const productData = data.product
  const [dialogOpen, setDialogOpen] = useState(false)
  const [isOpen, setIsOpen] = useState(false)
  const [photoIndex, setPhotoIndex] = useState(0)
  const [paths, setPaths] = useState([])
  const [images, setImages] = useState(() => {
    const initialState = getImages()
    return initialState
  })
  const [primaryImage, setPrimaryImage] = useState(() => {
    const initialState = getPrimaryImage()
    return initialState
  })
  const [fullImagesData, setFullImagesData] = useState(() => {
    const initialState = getFullImagesData()
    return initialState
  })

  useEffect(() => {
    $(tabs.current).easyResponsiveTabs({
      type: "horizontal",
      closed: false,
      tabidentify: "js-tabs-a", // The tab groups identifier
    })
  }, [])

  useEffect(() => {
    let categories = productData.categories
    if (categories[categories.length - 1] === "/new-arrivals") categories.pop()

    let segments = categories[categories.length - 1].split("/")
    segments.shift()

    let paths = [
      { name: productData.category, path: `catalog/${segments[0]}` },
      { name: productData.subCategory, path: `catalog${categories[0]}` },
    ]
    if (categories.length === 2)
      paths.push({
        name: productData.subCategoryDetail,
        path: `catalog${categories[1]}`,
      })

    paths.push({ name: productData.name, path: location.pathname.slice(1) })

    setPaths(paths)
  }, [])

  function getPrimaryImage() {
    if (!productData.images || productData.images.length === 0) return null

    let primaryImageLocal = productData.images.filter(function (imageData) {
      return imageData.isPrimary === true
    })[0]
    return primaryImageLocal
  }

  function getFullImagesData() {
    if (!productData.images || productData.images.length === 0) return null

    var nonPrimaryImages = productData.images.filter(function (imageData) {
      return imageData.isPrimary === false
    })
    nonPrimaryImages.sort(function (a, b) {
      return a.sortOrder - b.sortOrder
    })
    return nonPrimaryImages
  }

  function getImages() {
    if (!productData.images || productData.images.length === 0) return null

    let primaryImageLocal = getPrimaryImage()
    let nonPrimaryImages = getFullImagesData()
    var imagesLocal = nonPrimaryImages.map((imageData) => imageData.fullImage)
    if (primaryImageLocal) imagesLocal.unshift(primaryImageLocal.fullImage)

    return imagesLocal
  }

  function getPrice() {
    const price = productData.askingPrice;
    return price >= 1 && price <= 5000 ? price : null;
  }

  function formatPrice(price) {
    return price.toLocaleString("en-US");
  }
  
  const price = getPrice();

  return (
    <Layout>
      <SEO
        title={productData.name}
        description={productData.blurb}
        imageUrl={primaryImage ? primaryImage.fullImage : null}
        url={location.href}
        canonicalUrl={location.origin+location.pathname}
      />
      <JSONLD>
        <Product
          name={productData.name}
          description={productData.blurb}
          image={images}
          url={location.href}
          model={productData.model}
          sku={productData.productCode}
          mpn={productData.productCode}
          identifier={productData.serialNumber}
          releaseDate={productData.year}
          category={`${productData.category} > ${productData.subCategory} > ${productData.subCategoryDetail}`}
          
          itemCondition="https://schema.org/UsedCondition"
        >
          <Generic
            type="brand"
            jsonldtype="Brand"
            schema={{ name: productData.manufacturerName }}
          />
        </Product>
      </JSONLD>

      <Breadcrumb paths={paths} />

      <div className="row">
        <div className="medium-6 columns product-image">
          <div className="pl1 pr1 mb6">
            <a
              title={`Image of ${productData.name}`}
              onClick={() => {
                setPhotoIndex(0)
                setIsOpen(true)
              }}
            >
              <img
                id="large-image"
                className="border"
                src={primaryImage ? primaryImage.fullImage : null}
                alt={`Image of ${productData.name}`}
              />
            </a>
            <div className="zoom-container">
              <div className="zoom">
                <em className="icon-search"></em>
              </div>
            </div>
          </div>

          <div className="row column xsmall-up-4 small-up-6 mt0 product-thumbs mt3mb6">
            {fullImagesData
              ? fullImagesData.map((imageData, i) => (
                  <a
                    key={imageData.fullImage}
                    className="column mb2 swap-me"
                    alt={`${productData.name} image ${i}`}
                    onClick={() => {
                      setPhotoIndex(i + 1)
                      setIsOpen(true)
                    }}
                  >
                    <img
                      alt={`${productData.name} image ${i}`}
                      className="border"
                      src={imageData.gridImage}
                    />
                  </a>
                ))
              : null}
          </div>
        </div>

        {isOpen && images && (
          <Lightbox
            mainSrc={images[photoIndex]}
            nextSrc={images[(photoIndex + 1) % images.length]}
            prevSrc={images[(photoIndex + images.length - 1) % images.length]}
            onCloseRequest={() => setIsOpen(false)}
            onMovePrevRequest={() =>
              setPhotoIndex((photoIndex + images.length - 1) % images.length)
            }
            onMoveNextRequest={() =>
              setPhotoIndex((photoIndex + 1) % images.length)
            }
          />
        )}

        <div className="medium-6 columns pt6">
          <h1>{productData.name}</h1>
          <p>{productData.blurb}</p>
          <hr />
          {price !== null && (
            <p className="mt6 mb0 bold">
              Price: <span className="red bold">${formatPrice(price)}</span>
            </p>
          )}
          <p className="mt0 mb0 bold">
            Product Code:{" "}
            <span className="blue">{productData.productCode}</span>
          </p>
          <p className=" mb0 bold">
            Manufacturer:{" "}
            <span className="blue">
              <a href={`/manufacturer${productData.manufacturerSlug}/`}>
                {productData.manufacturerName}
              </a>
            </span>
          </p>
          <p className="mb0 bold">
            Model: <span className="blue">{productData.model}</span>
          </p>
          {productData.year && (
            <p className="mb0 bold">
              Year: <span className="blue">{productData.year}</span>
            </p>
          )}

          <p className="mb0 bold">
            Serial:{" "}
            <span className="blue">
              {productData.serialNumber ? productData.serialNumber : "N/A"}
            </span>
          </p>

          {productData.websiteLocation && (
            <p className="mb0 bold">
              Location: <span className="blue">{productData.websiteLocation}</span>
            </p>
          )}


          <p className="mt6 mb0">
            {productData.soldStatus === "On Hold" ||
            productData.soldStatus === "Invoiced" ? (
              <p style={{ color: "red" }}>
                <b>
                  This product is currently pending sale. Please Request a Quote
                  and a member of our Sales team will be in touch shortly to
                  discuss additional availability. Thank you.
                </b>
              </p>
            ) : null}

            {productData.soldStatus === "Sold" ? (
              <p style={{ color: "red" }}>
                <b>
                  Unfortunately, this product recently sold. Please fill out
                  your contact information and a member of our Sales team will
                  be in touch with you shortly to discuss availability. To
                  return to our full inventory please{" "}
                  <Link to="/buy-equipment"> Click here</Link>.
                </b>
                <br />
                <br />
                <Link className="button secondary tiny caps " to="/contact-us">
                  Contact Us
                </Link>
              </p>
            ) : (
              <a
                className="button secondary tiny caps "
                onClick={() => setDialogOpen(true)}
              >
                Request a Quote
              </a>
            )}

            <Link
              className="button secondary tiny caps "
              style={{ backgroundColor: "#166db6", margin: "0 0 0 5px" }}
              to="/sell-equipment"
            >
              Sell Similar
            </Link>

            <Modal
              open={dialogOpen}
              focusTrapped={true}
              center={true}
              closeOnEsc={true}
              onClose={() => setDialogOpen(false)}
            >
              <InquiryForm
                sfId={productData.sfId}
                key={productData.sfId}
                title="Request a Quote: "
                formSource="Quote Request"
                onClose={() => setDialogOpen(false)}
              />
            </Modal>
          </p>
          <br />
          <br />
          <Social url={location.href} title={productData.name} />
        </div>
      </div>

      <div className="row column mt8">
        <div id="js-tabs-horizontal" ref={tabs}>
          <ul className="resp-tabs-list js-tabs-a resp-htabs">
            <li>Product Details</li>
            {productData.documents.length > 0 && <li>Documents</li>}
            {productData.capabilities && <li>Capabilities</li>}
            {productData.video1 && <li>Videos</li>}
          </ul>

          <div className="resp-tabs-container js-tabs-a">
            <div>
              <div
                dangerouslySetInnerHTML={{ __html: `${productData.marketing}` }}
              />
              <br />
              {productData.description ? (
                <div
                  dangerouslySetInnerHTML={{
                    __html: `${productData.description}`,
                  }}
                />
              ) : null}
            </div>

            {productData.documents.length > 0 && (
              <div className="left-align">
                {productData.documents.map((doc) => (
                  <>
                    <a
                      key={doc.fileName}
                      target="_blank"
                      rel="noopener noreferrer"
                      href={doc.url}
                    >
                      {doc.fileName}
                    </a>
                    <br />
                  </>
                ))}
              </div>
            )}

            {productData.capabilities && (
              <div className="left-align">
                <div
                  dangerouslySetInnerHTML={{
                    __html: `${productData.capabilities}`,
                  }}
                />
              </div>
            )}

            {productData.video1 && (
              <div className="left-align">
                <div>
                  <ReactPlayer
                    url={`https://www.youtube.com/watch?v=${productData.video1}`}
                  />{" "}
                  <br />
                  {productData.video2 && (
                    <ReactPlayer
                      url={`https://www.youtube.com/watch?v=${productData.video2}`}
                    />
                  )}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default ProductDetail

export const query = graphql`
  query($slug: String!) {
    product(slug: { eq: $slug }) {
      name
      slug
      primaryPhoto
      model
      blurb
      description
      productCode
      askingPrice
      serialNumber
      manufacturerName
      manufacturerSlug
      sfId
      websiteLocation
      images {
        fullImage
        gridImage
        isPrimary
        sortOrder
        thumbImage
      }
      category
      subCategory
      subCategoryDetail
      marketing
      soldStatus
      year
      capabilities
      video1
      video2
      documents {
        url
        fileName
      }
      categories
    }
  }
`
